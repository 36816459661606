import L10nManager from './L10n.js';
import ApiManager from './Api.js';

export const VideoManager = {
    init() {},

    getVideo(date) {
        return ApiManager.getScheduledVideo(date, L10nManager.languageCode)
            .then(scheduledVideo => {
                return {
                    id: scheduledVideo.videoId.id,
                    name: scheduledVideo.videoId.translations[0].name,
                    src: ApiManager.getFileUrl(scheduledVideo.videoId.translations[0].file),
                };
            });
    },
};

export default VideoManager;
