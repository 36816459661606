import { CustomElement, CustomElementMeta } from 'application-frame/web/CustomElement.js';
import DataBinding from '@af-modules/databinding';
import template from './template.js';

const ItaSliderMeta = {
    name: 'ita-slider',
    attributes: {
        value: 'number',
        max: 'number',
    },

    get object() {
        return ItaSlider;
    },

    __proto__: CustomElementMeta,
};


const Private = {
    scope: Symbol('ItaSlider.Private.scope'),
};

const meta = ItaSliderMeta;

const createView = function(element) {
    return {
        get max() {
            return element.max ?? 100;
        },

        get percentage() {
            return element.value / (this.max);
        },

        get styleVariables() {
            return `--current-progress: ${this.percentage}; --current-offset: ${this.percentage * 100}%`;
        },

        onStartThumbDrag(event) {
            const moveHandler = this.onDragThumb.bind(this);

            window.addEventListener('mousemove', moveHandler);
            window.addEventListener('mouseup', function drop() {
                window.removeEventListener('mouseup', drop);
                window.removeEventListener('mousemove', moveHandler);
            });

            this.calculateValuefromMouse(event);
        },

        calculateValuefromMouse(event) {
            const box = element.getBoundingClientRect();
            const width = box.width;
            const percent = (event.clientX - box.left) / width;

            element.value = Math.min(Math.max(0, percent * this.max), this.max);
            element.dispatchEvent(new Event('input'));
        },

        onDragThumb(event) {
            this.calculateValuefromMouse(event);
        }
    };
};

const ItaSlider = {

    [Private.scope]: null,

    /** @type {number} */
    value: null,

    /** @type {number} */
    max: null,

    [meta.symbols.create]() {
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: createView(this), });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);
        this[Private.scope] = scope;

        DataBinding.attachBindings(scope, this, [
            { selector: 'root', name: 'bind-event', parameter: 'mousedown', value: 'view.onStartThumbDrag' },
        ]);
    },

    [meta.symbols.onPropertyChanged]() {
        this[Private.scope].update();
    },

    __proto__: CustomElement,
};

meta.prepare(meta.object);

customElements.define(meta.name, meta.object.constructor);
