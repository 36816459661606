import EventTarget from 'application-frame/core/EventTarget.js';
import Iterator from '@prograp/iterator';

import ApiManager from './Api.js';

/**
 * @mixes EventTarget
 */
export const L10nManager = {
    strings: null,
    languages: null,
    ready: null,

    get languageCode() {
        const langCode = localStorage.getItem('lang') || navigator.language;
        const [lang] = langCode.split('-');

        const languageExists = !!this.languages?.find(item => item.code === lang);

        return languageExists ? lang : 'en';
    },

    get currentLanguage() {
        const code = this.languageCode;

        return this.languages.find(item => item.code === code);
    },

    init() {
        this.constructor();

        this.ready = ApiManager.getLanguages()
            .then(languages => {
                this.languages = languages;

                return this.changeLanguage(this.languageCode);
            });
    },

    changeLanguage(languageCode) {
        return ApiManager.getLocalization(languageCode)
            .then(strings => {
                if (languageCode !== this.languageCode) {
                    localStorage.setItem('lang', languageCode);
                }

                this.strings = Iterator.new(strings)
                    .map((string) => [string.localization_key, string.value])
                    .intoObject();
                this.emit('change');
            });
    },

    translate(key, variables = {}) {
        if (!this.strings?.[key]) {
            return key;
        }

        const findVars = /%([^%]+)%/g;
        let string = this.strings[key];

        Iterator.new(function*() {
            let result = null;

            while ((result = findVars.exec(string))) {
                yield result[1];
            }

            return result;
        }()).forEach(name => {
            if (!variables[name]) {
                return;
            }

            string = string.replace(`%${name}%`, variables[name]);
        });

        return string;
    },

    __proto__: EventTarget,
};

export default L10nManager;
