import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature.js';
import { RenderEngine } from 'application-frame/rendering/index.js';
import currentWeekNumber from 'current-week-number';

import { Page, createView, defaultViewFactory } from '../lib/Page.js';
import { AudioTrackManager } from '../managers/AudioTrack.js';
import { L10nManager } from '../managers/L10n.js';
import { QuotesManager } from '../managers/Quotes.js';
import { VideoManager } from '../managers/Video.js';
import { SunriseManager } from '../managers/Sunrise.js';
import { SettingsManager } from '../managers/Settings.js';

const dayOfTheYear = function(date) {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;

    return Math.floor(diff / oneDay);
};

const getDateString = function(date) {
    const year = date.getFullYear();
    const month = (date.getMonth()+1).toLocaleString('en', { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString('en', { minimumIntegerDigits: 2 });

    return `${year}-${month}-${day}`;
};

const formatTime = function(isoDate, langCode) {
    return (new Date(isoDate)).toLocaleString(langCode, { hour: '2-digit', minute: '2-digit' });
};

export const MainPage = {
    template: 'page-main',
    path: ['/{index}'],
    name: 'MainPage',

    currentDate: new Map(),
    currentQuote: new Map(),
    currentAudioTrack: new Map(),
    currentVideo: new Map(),
    currentSunMoonTime: new Map(),

    days: (new Array(6)).fill(0).map((_, index) => index  * -1).reverse(),

    init(...args) {
        super.init(...args);

        SubscriberFeature(AudioTrackManager, this);
    },

    onRouteEnter() {
        super.onRouteEnter();

        this.days.forEach(day => {
            this.loadDay(day);
        });

        const element = document.querySelector(`.${this.template}`);


        RenderEngine.schedulePostRenderTask(() => {
            element.scrollLeft = element.scrollWidth;
        }, 'main-page-post-render-scroll');
    },

    loadDay(index) {
        const date = new Date();

        date.setHours(date.getHours() + (index * 24));

        const dateString = getDateString(date);
        const quote = QuotesManager.getQuote(dateString);

        this.currentDate.set(index, date);
        this.currentQuote.set(index, quote);

        Promise.all([
            AudioTrackManager.getAudioTrack(dayOfTheYear(date))
                .catch(() => null),
            VideoManager.getVideo(dateString)
                .catch(() => null),
            SunriseManager.get(dateString)
        ]).then(([audioTrack, video, sunriseData]) => {
            this.currentAudioTrack.set(index, audioTrack);
            this.currentVideo.set(index, video);
            this.currentSunMoonTime.set(index, sunriseData);

            this.scope.update();
        });
    },

    [AudioTrackManager.Events.Cached]() {
        this.validateAudioCache();
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get days() {
            return page.days;
        },

        get donationTitle() {
            return SettingsManager.donationTitle;
        },

        get donationContent() {
            return SettingsManager.donationContent;
        },

        get additionalContentTitle() {
            return SettingsManager.additionalContentTitle;
        },

        get additionalContent() {
            return SettingsManager.additionalContent;
        },

        currentQuote(day) {
            return page.currentQuote.get(day);
        },

        currentDate(day) {
            return page.currentDate.get(day)
                ?.toLocaleString('de', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' });
        },

        currentWeek(day) {
            return L10nManager.translate('general.week', {
                week: currentWeekNumber(page.currentDate.get(day))
            });
        },

        sun(day) {
            const {
                sunrise: { time: sunrise = '1990-01-01T00:00:00+00:00' } = {},
                sunset: { time: sunset = '1990-01-01T00:00:00+00:00' } = {},
            } = page.currentSunMoonTime.get(day) ?? {};

            return {
                down: this.l10n('general.clockHour', { time: formatTime(sunset) }),
                rise: this.l10n('general.clockHour', { time: formatTime(sunrise) }),
            };
        },

        moon(day) {
            const {
                moonrise: { time: moonrise = '1990-01-01T00:00:00+00:00' } = {},
                moonset: { time: moonset = '1990-01-01T00:00:00+00:00' } = {},
                moonphase: { value: moonphase = '50' } = {},
            } = page.currentSunMoonTime.get(day) ?? {};

            const moonIconIndex = Math.max(1, Math.round(Number.parseFloat(moonphase) / (100 / 30)));

            return {
                icon: `moon_${moonIconIndex}`,
                down: this.l10n('general.clockHour', { time: formatTime(moonset) }),
                rise: this.l10n('general.clockHour', { time: formatTime(moonrise) }),
            };
        },

        currentAudioTrack(day) {
            return page.currentAudioTrack.get(day);
        },

        currentVideo(day) {
            return page.currentVideo.get(day);
        },

        onShareQuote(event, { day }) {
            navigator?.share({
                text: this.l10n('general.shareQuote', {
                    quote: page.currentQuote.get(day).content,
                    author: page.currentQuote.get(day).author,
                }),
            });
        },

        __proto__: defaultViewFactory(page),
    };
}, MainPage);

export default MainPage;
