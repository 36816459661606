export const NetworkInfo = {
    Events: {
        Change: Symbol('NetworkInfo.Events.Change'),
    },

    get isOnline() {
        return navigator.onLine ?? true;
    },

    get type() {
        return navigator.connection?.type ?? (this.isOnline ? 'unknown' : 'none');
    },

    get effectiveType() {
        return navigator.connection?.effectiveType ?? '4g';
    },

    get saveData() {
        return navigator.connection?.saveData ?? false;
    }
};

export default NetworkInfo;
