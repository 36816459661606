export const SunriseManager = {
    host: 'https://api.met.no/weatherapi',
    version: '2.0',
    api: 'sunrise',
    cache: null,

    init() {
        this.cache = caches.open('sunrise-cache');
    },

    getLocation() {
        return (new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)))
            .then((location) => ({
                latitude: Math.floor(location.coords.latitude * 100) / 100,
                longitude: Math.floor(location.coords.longitude * 100) / 100,
            }));
    },

    getRequest(dateString, lat, lon, timezoneOffset) {
        const params = new URLSearchParams({
            date: dateString,
            lat,
            lon,
            offset: timezoneOffset
        });

        return new Request(`${this.host}/${this.api}/${this.version}/.json?${params.toString()}`);
    },

    purgeCache() {
        return this.cache
            .then(cache => {
                return Promise.all([cache, cache.keys()]);
            })
            .then(([cache, keys]) => {
                return Promise.all(keys.map(key => cache.delete(key)))
                    .then(() => cache);
            });
    },

    getTimezoneOffset() {
        const timezoneOffset = (new Date()).getTimezoneOffset();
        const timezoneOffsetHour = Math.floor((timezoneOffset * -1) / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
        const timezoneOffsetMinute = Math.abs(timezoneOffset % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });

        return `${timezoneOffsetHour}:${timezoneOffsetMinute}`;
    },

    fetch(request) {
        return fetch(request)
            .catch(() => {
                return this.cache.then(cache => cache.match(request));
            }).then(response => {
                if (!response) {
                    return this.cache.then(cache => cache.match(request, { ignoreSearch: true }))
                        .then(response => response.json());
                }

                return this.cache.then(cache => cache.put(request, response.clone()))
                    .then(() => response.json());
            });
    },

    get(dateString) {
        return this.getLocation()
            .then(location => {
                const timezoneOffset = this.getTimezoneOffset();

                return this.fetch(this.getRequest(dateString, location.latitude, location.longitude, timezoneOffset));
            }).then(data => {
                const time = data.location.time[0];

                return { sunrise: time.sunrise, sunset: time.sunset, moonrise: time.moonrise, moonset: time.moonset, moonphase: time.moonphase, };
            });
    }
};

export default SunriseManager;
