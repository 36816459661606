import ContentCache from './ContentCache.js';

const fetchAndCache = function(request) {
    return fetch(request).then(response => {
        return ContentCache
            .then(cache => cache.put(request, response.clone()))
            .catch(() => {})
            .then(() => response);
    });
};

export const cacheMiddleware = function(request) {
    return ContentCache
        .then(cache => {
            return cache.match(request);
        })
        .catch(() => undefined)
        .then(response => {
            const cacheRefresh = fetchAndCache(request);

            return response ?? cacheRefresh;
        });
};

export default cacheMiddleware;
