import Iterator from '@prograp/iterator';
import { Router } from '@af-modules/router';

import L10nManager from './managers/L10n.js';
import ApiManager from './managers/Api.js';
import AudioTrackManager from './managers/AudioTrack.js';
import QuotesManager from './managers/Quotes.js';
import VideoManager from './managers/Video.js';
import ScheduledNotificationsManager from './managers/ScheduledNotifications.js';
import SunriseManager from './managers/Sunrise.js';
import SettingsManager from './managers/Settings.js';
import CustomPageManager from './managers/CustomPage.js';

import MainPage from './pages/main.js';
import SettingsPage from './pages/settings.js';
import NotificationsPage from './pages/notifications.js';
import CustomPage from './pages/custom.js';

const Pages = [
    MainPage,
    SettingsPage,
    NotificationsPage,
    CustomPage,
];

const Managers = [
    ApiManager,
    L10nManager,
    AudioTrackManager,
    VideoManager,
    QuotesManager,
    ScheduledNotificationsManager,
    SunriseManager,
    SettingsManager,
    CustomPageManager,
];

export const main = function() {
    if (navigator.userAgent.includes('Safari')) {
        document.body.classList.add('ios');
    }

    Router.init();

    Iterator.new(Pages)
        .forEach(page => Router.addRoutable(page.path, page));

    const managersReady = Iterator.new(Managers)
        .map(manager => {
            manager.init();

            return manager.ready ?? Promise.resolve();
        }).intoArray();

    Promise.all(managersReady).then(() => Router.restore());
};
