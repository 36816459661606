import EventTarget from 'application-frame/core/EventTarget.js';
import Iterator from '@prograp/iterator';

import ApiManager from './Api.js';
import L10nManager from './L10n.js';
import { canDownloadAudioTracks } from '../lib/NetworkRules.js';
import ContentCache from '../lib/ContentCache.js';

const Callbacks = {
    onL10nChanged: Symbol('AudioTrackManager.Callbacks.onL10nChanged'),
};

export const AudioTrackManager = {
    Events: {
        Cached: Symbol('AudioTrackManager.Events.Cached'),
    },

    /**
     * @type {Array}
     */
    tracks: null,

    /**
     * @type {Promise}
     */
    ready: null,

    init() {
        this.constructor();
        this.updateAudioTracks();

        L10nManager.on('change', this[Callbacks.onL10nChanged].bind(this));
    },

    updateAudioTracks() {
        this.ready = ApiManager.getAudioFiles(L10nManager.languageCode);
        this.ready.then(tracks => {
            this.tracks = tracks;
            this.cacheAudioTracks();
        });
    },

    /**
     * @return {Promise.<boolean>}
     */
    validateIsCached() {
        return ContentCache.then(cache => {
            const tracks = Iterator.new(this.tracks)
                .map(track => {
                    const request = ApiManager.getAudioFileRequest(track.file);

                    return cache.match(request);
                }).intoArray();

            return Promise.all(tracks)
                .then((tracks) => tracks.every(response => !!response));
        });
    },

    /**
     * @return {Promise}
     */
    cacheAudioTracks() {
        if (!canDownloadAudioTracks()) {
            return;
        }

        const files = this.tracks.map(track => {
            const request = ApiManager.getAudioFileRequest(track.file);

            ContentCache.then(cache => {
                return Promise.all([cache, cache.match(request)]);
            }).then(([cache, response]) => {
                if (response) {
                    return;
                }

                return cache.add(request);
            });
        });

        return Promise.all(files)
            .then(() => this.emit(this.Events.Cached));
    },

    getAudioTrack(id) {
        const index = id % this.tracks.length;

        const track = this.tracks[index];

        return ApiManager.getAudioFile(track.file)
            .then(blob => {
                return {
                    id: track.Audio_id,
                    name: track.name,
                    title: track.title,
                    src: URL.createObjectURL(blob),
                };
            });
    },

    [Callbacks.onL10nChanged]() {
        this.updateAudioTracks();
    },

    __proto__: EventTarget,
};

export default AudioTrackManager;
