import { Page, createView, defaultViewFactory } from '../lib/Page.js';
import CustomPageManager from '../managers/CustomPage.js';
import { L10nManager } from '../managers/L10n.js';

export const SettingsPage = {
    template: 'page-settings',
    path: '/settings',
    name: 'SettingsPage',

    onRouteEnter() {
        super.onRouteEnter();
    },

    __proto__: Page,
};

createView((page) => {
    return {

        onGoBack() {
            window.history.back();
        },

        get customPages() {
            return CustomPageManager.getPages();
        },

        getPageLink(page) {
            return `#!/settings/custom/${page.id}`;
        },

        get availableLanguages() {
            return L10nManager.languages;
        },

        get languageItemOptions() {
            return { lang: L10nManager.currentLanguage.name };
        },

        isSelectedLanguage(lang) {
            return L10nManager.languageCode === lang.code;
        },

        onChangeLanguage(event) {
            L10nManager.changeLanguage(event.target.value);
        },

        __proto__: defaultViewFactory(page),
    };
}, SettingsPage);

export default SettingsPage;
