import { cacheMiddleware } from '../lib/CacheMiddleware.js';

const deepFilters = function(fields) {
    return { deep: JSON.stringify(fields), };
};

const filters = function(fields) {
    return { filter: JSON.stringify(fields), };
};

const languageFilter = function(languageCode) {
    return { languages_code: { _eq: languageCode } };
};

export const ApiManager = {

    host: null,

    init() {
        this.host = 'https://rsc.prograp.dev';
    },

    requestRaw(path, init) {
        const request = new Request(this.host + path, init);

        return cacheMiddleware(request);

    },

    request(path, init) {
        return this.requestRaw(`/items${path}`, init)
            .then(response => response.json())
            .then(body => body.data);
    },

    getLanguages() {
        return this.request('/languages');
    },

    getLocalization(languageCode) {
        const params = new URLSearchParams({
            ...filters({
                ...languageFilter(languageCode)
            }),
        });

        return this.request(`/localization_translations?${params.toString()}`);
    },

    getAudioFiles(languageCode) {
        const params = new URLSearchParams({
            ...filters({
                ...languageFilter(languageCode),
            }),
        });

        return this.request(`/Audio_translations?${params.toString()}`);
    },

    getCurrentQuotes(languageCode) {
        const params = new URLSearchParams({
            fields: '*,quoteId.*,quoteId.translations.*',
            sort: 'date',


            ...filters({
                date: {
                    _between: ['$NOW(-6 days)', '$NOW(+7 days)']
                }
            }),

            ...deepFilters({
                quoteId: {
                    translations: {
                        _filter: {
                            ...languageFilter(languageCode),
                        },
                    },
                },
            }),
        });

        return this.request(`/QuoteSchedule?${params.toString()}`);
    },

    getAudioFileRequest(fileId) {
        return new Request(this.getFileUrl(fileId));
    },

    getAudioFile(fileId) {
        return cacheMiddleware(this.getAudioFileRequest(fileId))
            .then(response => response.blob());
    },

    getScheduledVideo(date, languageCode) {
        const params = new URLSearchParams({
            fields: '*.*,videoId.translations.*',

            ...filters({
                date: {
                    _eq: date,
                },
            }),

            ...deepFilters({
                videoId: {
                    translations: {
                        _filter: {
                            ...languageFilter(languageCode),
                        },
                    },
                },
            })
        });

        return this.request(`/VideoSchedule?${params.toString()}`)
            .then(list => list[0] ?? null);
    },

    getAppSettings(languageCode) {
        const params = new URLSearchParams({
            ...filters({
                ...languageFilter(languageCode),
            }),
        });

        return this.request(`/AppSettings_translations?${params.toString()}`)
            .then(list => list[0] ?? null);
    },

    getCustomPages(languageCode) {
        const params = new URLSearchParams({
            fields: 'translations.*',

            ...filters({
                status: {
                    _eq: 'published',
                }
            }),

            ...deepFilters({
                translations: {
                    _filter: {
                        ...languageFilter(languageCode),
                    },
                },
            })
        });

        return this.request(`/CMS?${params.toString()}`)
            .then(list => list?.map(item => item.translations[0]) ?? null);
    },

    getFileUrl(fileId) {
        return `${this.host}/assets/${fileId}`;
    }
};

export default ApiManager;
