import { Page, createView, defaultViewFactory } from '../lib/Page.js';
import { ScheduledNotificationsManager } from '../managers/ScheduledNotifications.js';

export const NotificationsPage = {
    template: 'page-notifications',
    path: '/settings/notifications',
    name: 'NotificationsPage',

    notificationTitleCache: null,

    init(...args) {
        super.init(...args);

        this.notificationTitleCache = new Map();
    },

    onRouteEnter() {
        super.onRouteEnter();
    },

    __proto__: Page,
};

createView((page) => {
    return {
        selectedTime: '08:00',
        selectedType: '',

        audioLabel: 'general.notification.audio.name',
        videoLabel: 'general.notification.video.name',
        quoteLabel: 'general.notification.quote.name',

        get notifications() {
            return ScheduledNotificationsManager.registeredNotifications;
        },

        get canNotCreate() {
            return this.selectedType === null || this.selectedType === '';
        },

        onGoBack() {
            window.history.back();
        },

        onScheduleNotification() {
            const [hour, minute] = this.selectedTime.split(':').map(item => Number.parseInt(item));

            ScheduledNotificationsManager
                .setNotification(this.selectedType, { hour, minute, second: 0 })
                .then(() => page.scope.update());
        },

        getNotificationTitle(dataString) {
            if (!page.notificationTitleCache.has(dataString)) {
                const data = JSON.parse(dataString);
                const date = new Date();

                date.setHours(data.time.hour);
                date.setMinutes(data.time.minute);
                date.setSeconds(data.time.second);

                const time = date.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' });
                const typeTranslation = this.l10n(`general.notification.${data.type}.name`);
                const notificationTitle = `${typeTranslation} ${this.l10n(this.strings.atTime)} ${this.l10n('general.clockHour', { time })}`;

                page.notificationTitleCache.set(dataString, notificationTitle);
            }

            return page.notificationTitleCache.get(dataString);
        },

        onRemoveNotification(event, { notification }) {
            ScheduledNotificationsManager
                .clearNotification(notification.id)
                .then(() => page.scope.update());
        },

        __proto__: defaultViewFactory(page),
    };
}, NotificationsPage);

export default NotificationsPage;
