import { Page, createView, defaultViewFactory } from '../lib/Page.js';
import { CustomPageManager } from '../managers/CustomPage.js';

export const DonationPage = {
    template: 'page-custom',
    path: '/settings/custom/{id}',
    name: 'DonationPage',

    data: null,

    onRouteEnter(...args) {
        super.onRouteEnter(...args);

        const [, params] = args;

        this.data = CustomPageManager.getPage(parseInt(params.id, 10));
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get title() {
            return page.data?.title;
        },

        get htmlContent() {
            return page.data?.content;
        },

        onGoBack() {
            return window.history.back();
        },

        __proto__: defaultViewFactory(page),
    };
}, DonationPage);

export default DonationPage;
