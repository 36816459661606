import ApiManager from './Api.js';
import L10nManager from './L10n.js';

const Callbacks = {
    onL10nChanged: Symbol('AudioTrackManager.Callbacks.onL10nChanged'),
};

export const CustomPageManager = {

    /**
     * @type {Promise}
     */
    ready: null,

    /**
     * @type {Array}
     */
    pages: null,

    init() {
        L10nManager.on('change', this[Callbacks.onL10nChanged].bind(this));

        this.ready = L10nManager.ready
            .then(() => ApiManager.getCustomPages(L10nManager.languageCode))
            .then(pages => {
                this.pages = pages;
            });
    },

    getPage(id) {
        return this.pages?.find(page => page.CMS_id === id);
    },

    getPages() {
        return this.pages?.map(page => ({ id: page.CMS_id, title: page.title }));
    },

    [Callbacks.onL10nChanged]() {
        ApiManager.getCustomPages(L10nManager.languageCode)
            .then(pages => {
                this.pages = pages;
            });
    }
};

export default CustomPageManager;
