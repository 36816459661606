import NetworkInfo from './NetworkInfo.js';

export const canDownloadAudioTracks = function() {
    const connectionBlacklist = ['cell', '2g', '3g', '4g'];

    return NetworkInfo.isOnline && !NetworkInfo.dataSaver && !connectionBlacklist.includes(NetworkInfo.type);
};

export const canPlayVideos = function() {
    return NetworkInfo.isOnline;
};
