import ApiManager from './Api.js';
import L10nManager from './L10n.js';

const Callbacks = {
    onL10nChanged: Symbol('AudioTrackManager.Callbacks.onL10nChanged'),
};

export const SettingsManager = {

    /**
     * @type {Promise}
     */
    ready: null,

    /**
     * @type {Array}
     */
    settings: null,

    init() {
        L10nManager.on('change', this[Callbacks.onL10nChanged].bind(this));

        this.ready = L10nManager.ready
            .then(() => ApiManager.getAppSettings(L10nManager.languageCode))
            .then(settings => {
                this.settings = settings;
            });
    },

    /**
     * @return {string|null}
     */
    get donationTitle() {
        return this.settings?.donationTitle;
    },

    /**
     * @return {string|null}
     */
    get donationContent() {
        return this.settings?.donationContent;
    },

    /**
     * @return {string|null}
     */
    get additionalContentTitle() {
        return this.settings?.additionalContentTitle;
    },

    /**
     * @return {string|null}
     */
    get additionalContent() {
        return this.settings?.additionalContent;
    },

    [Callbacks.onL10nChanged]() {
        ApiManager.getAppSettings(L10nManager.languageCode)
            .then(settings => {
                this.settings = settings;
            });
    }
};

export default SettingsManager;
