import ApiManager from './Api.js';
import L10nManager from './L10n.js';

const Callbacks = {
    onL10nChanged: Symbol('AudioTrackManager.Callbacks.onL10nChanged'),
};

export const QuotesManager = {

    /**
     * @type {Promise}
     */
    ready: null,

    /**
     * @type {Array}
     */
    quotes: null,

    init() {
        L10nManager.on('change', this[Callbacks.onL10nChanged].bind(this));

        this.ready = L10nManager.ready
            .then(() => ApiManager.getCurrentQuotes(L10nManager.languageCode))
            .then(quotes => {
                this.quotes = quotes;
            });
    },

    /**
     * @param  {string} date
     *
     * @return {object|null}
     */
    getQuote(date) {
        const data = this.quotes?.find(quote => quote.date === date) ?? null;

        if (!data) {
            return null;
        }

        return {
            content: data.quoteId.translations[0].content,
            author: data.quoteId.author,
        };
    },

    [Callbacks.onL10nChanged]() {
        ApiManager.getCurrentQuotes(L10nManager.languageCode)
            .then(quotes => {
                this.quotes = quotes;
            });
    }
};

export default QuotesManager;
