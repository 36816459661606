import { L10nManager } from './L10n.js';

const cordova = window.cordova ?? {};

const Type = {
    Video: 'video',
    Audio: 'audio',
    Quote: 'quote',
};

const Callbacks = {
    onNotificationClick: Symbol('ScheduledNotificationsManager.Callbacks.onNotificationClick'),
};

export const ScheduledNotificationsManager = {
    Type,
    ready: null,
    registeredNotifications: null,

    init() {
        this.registeredNotifications = null;
        this.ready = (new Promise((resolve) => {
            if (!('cordova' in window)) {
                resolve(null);
            }

            document.addEventListener('deviceready', resolve);
        })).then(() => {
            cordova.plugins?.notification.local.on('click', this[Callbacks.onNotificationClick]);

            return this.fetchRegisteredNotifications();
        });
    },

    fetchRegisteredNotifications() {
        return (new Promise(resolve => {
            const api = cordova.plugins?.notification?.local;

            if (!api) {
                resolve([]);
            }

            api.getAll(resolve);
        })).then(list => {
            this.registeredNotifications = list;

            return list;
        });
    },

    setNotification(type, time) {
        if (!time.hour || typeof time.hour !== 'number') {
            throw new TypeError('notification type must contain a numberic hour property');
        }

        if (time.minute && typeof time.minute !== 'number') {
            throw new TypeError('notification type must contain a numberic minute property');
        }

        return (new Promise(resolve => cordova.plugins?.notification?.local?.schedule({
            id: Date.now(),
            data: {
                type,
                time,
            },
            group: type,
            title: L10nManager.translate(`general.notification.${type}.title`),
            text: L10nManager.translate(`general.notification.${type}.text`),
            trigger: { every: time },
        }, resolve))).then(() => {
            return this.fetchRegisteredNotifications();
        });
    },

    clearNotification(id) {
        return new Promise(resolve => cordova.plugins?.notification?.local?.cancel(id, resolve))
            .then(status => {
                return this.fetchRegisteredNotifications().then(() => status);
            });
    },

    [Callbacks.onNotificationClick](notification) {
        console.log(notification);
        alert(JSON.stringify(notification.data));
    }
};

export default ScheduledNotificationsManager;
