import { Component, ComponentCallbacks, defaultViewFactory as componentViewFactory } from './Component.js';
import { MagicTranslationKeyResolver } from './MagicTranslationKeyResolver.js';

export const Page = {
    name: 'UnnamedPage',
    active: false,
    parent: null,

    [ComponentCallbacks.onL10nChanged]() {
        if (!this.active) {
            return;
        }

        super[ComponentCallbacks.onL10nChanged]();
    },

    onRouteEnter() {
        this.lazyInit();

        this.active = true;
        this.scope.update();
    },

    onRouteLeave() {
        this.active = false;
        this.scope.update();
    },

    lazyInit() {
        if (!this.template || this.inflated) {
            return;
        }

        this.init(`#${this.template}`);
    },

    init(template, pageTranslationKey = null) {
        if (!this.strings) {
            this.strings = MagicTranslationKeyResolver.new('page', this, pageTranslationKey);
        }

        this.instantiateTemplate(template);

        return super.init();
    },

    __proto__: Component,
};

export const defaultViewFactory = function(page) {
    return {
        get active() {
            return page.active;
        },

        __proto__: componentViewFactory(page),
    };
};

export default Page;

export { createView } from './Component.js';
