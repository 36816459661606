import { CustomElement, CustomElementMeta } from 'application-frame/web/CustomElement.js';
import template from './template.js';

const ItaIconMeta = {
    name: 'ita-icon',

    get object() {
        return ItaIcon;
    },

    __proto__: CustomElementMeta,
};

const meta = ItaIconMeta;

const ItaIcon = {
    [meta.symbols.create]() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(document.importNode(template.content, true));
    },

    __proto__: CustomElement,
};

meta.prepare(meta.object);

customElements.define(meta.name, meta.object.constructor);
