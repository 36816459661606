import { CustomElement, CustomElementMeta } from 'application-frame/web/CustomElement.js';
import DataBinding from '@af-modules/databinding';
import template from './template.js';

const ItaMediaPlayerMeta = {
    name: 'ita-media-player',
    attributes: {
        video: 'string',
        audio: 'string',
    },

    get object() {
        return ItaMediaPlayer;
    },

    __proto__: CustomElementMeta,
};


const Private = {
    scope: Symbol('ItaMediaPlayer.Private.scope'),
};

const meta = ItaMediaPlayerMeta;

const createView = function(element) {
    return {
        get videoSrc() {
            return element.video;
        },

        get audioSrc() {
            return element.audio;
        },

        get totalTimeText() {
            return `${Math.floor(this.totalTime / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${Math.round(this.totalTime % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
        },

        get elapsedTimeText() {
            return `${Math.floor(this.elapsedTime / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${Math.round(this.elapsedTime % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
        },

        onUpdateTotalTime(event) {
            this.totalTime = event.target.duration;
        },

        onTogglePlayback() {
            const audio = element.shadowRoot.querySelector('audio');

            audio.paused ? audio.play() : audio.pause();
        },

        onUpdateElapsed(event) {
            this.elapsedTime = event.target.currentTime;
        },

        onSeekPosition(event) {
            const audio = element.shadowRoot.querySelector('audio');

            audio.currentTime = event.target.value;
        },

        isPlayingAudio: false,
        elapsedTime: 0,
        totalTime: 0,

        onStartVideo() {
            const video = element.shadowRoot.querySelector('video');

            // monkey patch fullscreen api
            video.requestFullscreen = video.requestFullscreen ?? video.webkitEnterFullscreen;

            video.requestFullscreen();
            video.play();
        },

        onExitFullscreen(event) {
            if (document.fullscreenEnabled || document.webkitFullscreenEnabled) {
                return;
            }

            event.target.pause();
        },

        videoPaused: true,
    };
};

const ItaMediaPlayer = {

    [Private.scope]: null,

    /** @type {string} */
    video: null,

    /** @type {string} */
    audio: null,

    [meta.symbols.create]() {
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: createView(this), });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);
        this[Private.scope] = scope;

        if (this.ownerDocument.body.classList.contains('ios')) {
            this.classList.add('ios');
        }
    },

    [meta.symbols.onPropertyChanged]() {
        this[Private.scope].update();
    },

    __proto__: CustomElement,
};

meta.prepare(meta.object);

customElements.define(meta.name, meta.object.constructor);
